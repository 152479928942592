<template>
  <div class="page">
    <!--定制商机-->
    <div class="top-title">
      <div>
        <span></span>
      </div>
    </div>

    <div class="box">
      <!--定制商机-->
      <div class="customized-box">
        <div>
          <h5>
<!--            <span>定制商机</span>-->
            <span>添加您关注的招工关键词 </span><span style="color:#c8c9cc">最多可添加6个关键词 </span>
            <van-tag size="large" type="primary" @click="showAddKey=true" v-if="keys.length<6">添加</van-tag>
            <van-tag size="large" style="display:none"></van-tag>
          </h5>
          <div class="customized">
            <template>
              <van-tag size="large" style="margin-bottom:8px;" plain closeable type="primary" v-for="k in keys" :key="k.id" @close="del(k)">{{ k.word }} </van-tag>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div style="">
      <van-button round block type="info" native-type="submit" class="save-btn" @click="go('home')">添加完成</van-button>
    </div>
    <van-dialog v-model="showAddKey" title="添加订阅关键词" show-cancel-button @confirm="addKey" @cancel="showAddKey=false">
      <van-field v-model="keyData.word" placeholder="请输入订阅关键词" />
    </van-dialog>
  </div>
</template>

<script>
import {getKey,addKey,delKey} from "../../api/userKey";
import store from '../../store'
import { Dialog,Toast } from 'vant';
export default {
  data() {
    return {
      user:{},
      keys:[],
      keyData:{
        word:''
      },
      showAddKey: false,
    };
  },
  mounted() {
    this.loadKey();
    this.user = JSON.parse(`${store.state.user}`)
    console.info("index,user:",this.user)
  },
  methods: {
    async loadKey() {
      let r = await getKey();
      this.keys = r.data.content;
      console.info("loadKey",this.keys)
    },
    async addKey() {
      if(!this.keyData.word){
        Toast.fail("请填写订阅词");
        return;
      }
      let r = await addKey(this.keyData);
      this.loadKey()
      this.showAddKey=false;
      this.keyData.word=''
      Toast.success('添加成功');
    },
    async del(e){
      let r = await delKey(e);
      this.loadKey()
      console.info("del",e)
    },
    go(url) {
      this.$router.push(url);
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
body {
  background: #f6f6f6;
}

.save-btn {
  position: absolute;
  bottom: 0;
}

.top-title {
  height: 45px;
  background: #1083fc;
  color: #ffffff;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin: auto;
      padding-top: 5px;
      font-size: 16px;
    }
  }
}

.rotation-picture {
  max-width: 700px;
  border-radius: 7px;
  width: 94%;
  margin: 0;
  height: 158px;
  background: #ffffff;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -120px);
}

.box {
  border-radius: 20px;
  background: #fafafa;
  height: 750px;
  margin-top: -30px;
  padding: 10px;

  .customized-box {
    background: #ffff;
    margin-top: 20px;
    height: 100%;
    padding-top: 2px;
    padding-left: 5px;
    h5 {
      border-left: 3px solid #286efc;

      :first-child {
        margin-left: 15px;
      }

      :last-child {
        margin-left: 18px;
        font-weight: 500;
        color: #8b8d90;
        font-size: 15px;
      }
    }
  }

  .customized {
    background: #ffffff;
    min-height: 26px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px;
    padding-top: 0px;
    padding-bottom: 10px;

    span {
      margin-right: 6px;
    }
  }
}
</style>

<style>
.c-nav-bar{
  margin-top: 0px;
}
.selected-box .add-info .van-button__content .van-button__text{
  margin-left: 0px;
}
.van-nav-bar__left{
  display: none;
}
</style>
